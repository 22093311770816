.program-name{
    color: #bbc2d0;
    font-size: 15px;
    font-weight: 700;
}
.program-info-div{
    display: flex;
    justify-content: space-between;
}
.pg-info-clr{
    color: #bbc2d0;
    font-size: 13px;
    font-weight:700;
}
.container {
    display: flex;
  }
  
  .profile-div {
    flex: 0 0 25%;

  }
  
  .accountName-div {
    flex: 0 0 75%;
    
  }
  .accountDetails{
    color:#bbc2d0;
    font-size:16px;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    display: inline-block; 
    margin-right: 5px;
  }
  .status-label {
    text-transform: capitalize;
  }
  .status_title{
    font-size: 14px;
    margin-top: 10px;
  }